
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {FileDto, SwaggerException} from "@/shared/service-proxies/model";
import apiHttpClient from '@/shared/utils/api-http-client';
import {AppConsts} from '@/abpPro/AppConsts';
import {throwError} from "rxjs";
import {UrlHelper} from "../../../helpers";
import {message} from "ant-design-vue";
import {formatInternalValue} from "ant-design-vue/lib/vc-tree-select/src/util";
import objectHelper from "../../../helpers/objectHelper";

let workflowSchemeApi = {
    namespaces:'WorkflowSchemeInfo',
    instance: apiHttpClient,
    locked: false,
    localStore: {},
    getInstance: function () {
        return this.instance = this.instance ? this.instance : axios.create()
    },
    getPagedList: async function (params) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/"+this.namespaces+"/GetPaged";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, params),
            headers: {
                Accept: "application/json",
            },
        };
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data

    },
    getScheme: async function (id) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/workflowScheme/GetEditById";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, {id:id}),
            headers: {
                Accept: "application/json",
            },
        };
        const _response = await this.getInstance().request(options_);
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data

    },
     getNode(scheme,nodeId){
        //本地查找一个节点
        return scheme.nodes.find(item=>{
             return  item.id == nodeId
        })
     },
    getById: async (itemId) => {

    },
    async getNodeSchemeByNodeId(processId,  nodeId){
        console.log('getByCode')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/Workflow/getNodeScheme";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_,{ processId:processId,  nodeId:nodeId }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
        };
        const _response = await this.getInstance().request(options_);
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
        }
        return _response.data
    },
    getStartNode:async function(code){
        console.log('getByCode')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/Workflow/GetStartNode";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_,{code:code}),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
        };
        const _response = await this.getInstance().request(options_);
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
        }
        return _response.data
    },
    getByCode: async function (code){
         console.log('getByCode')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/"+this.namespaces+"/GetFlowParam";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_,{code:code}),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
        };
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data

    },
    async save(data) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/"+this.namespaces+"/SaveWorkflowSchemeInfo";
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
    },
            data: data
        };
        console.log(options_)
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    }
}

export default workflowSchemeApi