<template>
  <a-spin :spinning="spinning">
    <div class="modal-header" style="margin-bottom: 10px">
      <div class="modal-title">
        <a-icon type="medicine-box" />
        <span span>{{this.formModel.title}}</span>
      </div>
    </div>

    <a-form-model
      :rules="validModel"
      ref="ruleForm"
      :layout="form.layout"
      :model="formModel"
      v-bind="formItemLayout"
    >
      <a-tabs>
        <a-tab-pane key="1">
          <span slot="tab"> 流转记录 </span>
          <a-timeline>
            <a-timeline-item v-for="(item, index) in timeLines" :key="index">{{
              item.title
            }}</a-timeline-item>
          </a-timeline>
        </a-tab-pane>

        <a-tab-pane key="2" forceRender>
          <span slot="tab"> 流程信息 </span>
          <div
            id="workflowshcemeinfo"
            class="tab-pane"
            style="position: relative; padding: 5px; height: 600px"
          >
            <div
              style="
                position: relative;
                width: 100%;
                height: 100%;
                border: 1px solid #ccc;
                padding-bottom: 40px;
              "
            >
              <div id="wf_shcemeinfo"></div>
              <div
                style="
                  position: absolute;
                  left: 0px;
                  bottom: 0;
                  width: 100%;
                  height: 40px;
                  border-top: 1px solid #ddd;
                  line-height: 39px;
                  text-align: right;
                  padding-right: 10px;
                  font-size: 16px;
                "
              >
                <div style="display: inline-block; margin-left: 10px">
                  <i
                    style="padding-right: 5px; color: #5bc0de"
                    class="fa fa-square"
                  ></i
                  ><span>正在处理</span>
                </div>
                <div style="display: inline-block; margin-left: 10px">
                  <i
                    style="padding-right: 5px; color: #5cb85c"
                    class="fa fa-square"
                  ></i
                  ><span>已处理</span>
                </div>
                <div style="display: inline-block; margin-left: 10px">
                  <i
                    style="padding-right: 5px; color: #999"
                    class="fa fa-square"
                  ></i
                  ><span>未处理</span>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-form-model>
    <!-- <div class="modal-footer">
      <a-button type="danger" @click="cancle">取消</a-button>
      <a-button type="primary" @click="onSubmit">保存</a-button>
    </div> -->
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";

import { message } from "ant-design-vue";
import dataItemApi from "@/shared/service-proxies/dataItem/services/dataItem-proxies";
import sysInterfaceApi from "../../../shared/service-proxies/sysInterface/services/sys-interface-proxies";
import workflowSchemeApi from "../../../shared/service-proxies/workflow/services/workflow-scheme-proxies";
// import nodeForm from "./node/nodeForm";
import "@/shared/workflow/zr-workflow-ui.js";
import Bus from "@/shared/bus/bus";
import modalHelper from "../../../shared/helpers/modal/modal-helper";
import sysInterfaceSelectForm from "../../systemManagement/sysInterface/selectForm/index";
import objectHelper from "../../../shared/helpers/objectHelper";
import { CommonServiceProxy } from "@/shared/common-service";
import Vue from "vue";

export default {
  name: "workflow-form",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      fieldsJsonStr: "",
      commonService: null,
      timeLines: [],
      fieldsJson: {},
      labelCol: {
        span: 6,
        offset: 0,
      },
      interfaceTypes: [],
      requestMethods: [],
      curInternalInterfaceTree: [],
      internalServices: [],
      fieldMaps: {
        requestMethods: {
          value: "id",
          label: "itemName",
        },
      },
      form: {
        layout: "horizontal",
      },
      mode: "create",
      curDataItemTree: [],
      spinning: false,
      // 表单
      formLayout: "horizontal",
      // 选中的权限过滤
      selectedPermission: [],
      formModel: {
        id: "",
        name: "",
        code: "",
        category: "",
        enabledMark: 0,
        isInApp: "",
        description: "",
        scheme: "",
        processId: "",
      },
      history: [],
      validModel: {
        //todo 表单验证
      },
    };
  },
  mounted() {
    this.initWorkflowDesigner();
  },
  components: {},
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.fullData(); // 模态框必须,填充数据到data字段
    console.log(this.mode);
    console.log(this.formModel);
    this.getData(this.formModel.id);
    if (this.mode == "edit") {
      workflowSchemeApi.getScheme(this.formModel.schemeId).then((res) => {
        let scheme = res.content;
        this.formModel.scheme = scheme;
      });
    }
  },
  computed: {
    formItemLayout() {
      const { layout } = this.form;
      return layout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : {};
    },
    buttonItemLayout() {
      const { layout } = this.form;
      return layout === "horizontal"
        ? {
            wrapperCol: { span: 14, offset: 4 },
          }
        : {};
    },
  },
  methods: {
    async getData(pid) {
      let params = {
        processId: pid,
      };
      try {
        let data = await this.commonService.get({
          url: "/api/services/app/WorkflowProcess/GetProcessDetails",
          params: params,
        });
        if (data) {
          var info = data.info;
          //page.taskInfo = data.task;
          var schemeObj = JSON.parse(info.scheme);
          //  history = info.taskLogList;
          //   currentIds = info.CurrentNodeIds;

          this.loadTimeLine(data.task || [], info || {});
          this.loadFlowInfo(schemeObj, data.task || [], info || {});
        }
      } catch (error) {}
    },
    loadTimeLine: function (taskInfo, info) {
      console.log(taskInfo);
      var nodelist = [];
      var history = info.taskLogList || [];
      // 当前节点处理人信息
      $.each(taskInfo, function (_index, _item) {
        var nameList = [];
        $.each(_item.workflowUserInfoList, function (_jindex, _jitem) {
          if (_jitem.mark == 0) {
            nameList.push(_jitem.id);
          }
        });

        var point = {
          title: _item.nodeName + "【正在处理节点】",
          people: String(nameList),
          content: "需要其审核",
          time: "当前",
        };
        //Vue.set(this.nodelist, "", point);
        nodelist.push(point);
      });
      // 历史审核记录
      var anodeinfo = {};
      var $anodeinfo = $(".tab-flow-audit");
      for (var i = 0, l = history.length; i < l; i++) {
        var item = history[i];

        var content = item.operationName;
        if (item.des) {
          content += "<br/>【审批意见】：" + item.des;
        }

        var nodeName = "";
        if (item.F_NodeId && page.nodeMap[item.nodeId]) {
          nodeName = page.nodeMap[item.nodeId].name;
        }

        var point = {
          title: item.nodeName || nodeName,
          people: item.createUserId,
          peopleName: item.createUserName,
          content: content,
          time: item.createDate,
        };

        nodelist.push(point);
      }

      this.timeLines = nodelist;
      //$("#wf_timeline").lrtimeline(nodelist, isFinished);
    },
    loadFlowInfo: function (scheme, taskInfo, info) {
      console.log(info);
      console.log(taskInfo);
      var nodeInfoes = {};
      var strcurrentIds = String(info.currentNodeIds || []);
      var history = info.taskLogList || [];
      var nodeMap = {};
      // 当前节点处理人信息
      $.each(taskInfo, function (_index, _item) {
        var nameList = [];
        $.each(_item.workflowUserInfoList, function (_jindex, _jitem) {
          if (_jitem.Mark == 0) {
            nameList.push(_jitem.id);
          }
        });
        var point = {
          namelist: nameList,
        };
        nodeInfoes[_item.nodeId] = nodeInfoes[_item.nodeId] || [];
        nodeInfoes[_item.nodeId].push(point);
      });
      // 初始化工作流节点历史处理信息
      $.each(history, function (id, item) {
        nodeInfoes[item.nodeId] = nodeInfoes[item.nodeId] || [];
        nodeInfoes[item.nodeId].push(item);
      });
      $.each(scheme.nodes, function (_index, _item) {
        //0正在处理 1 已处理同意 2 已处理不同意 3 未处理
        _item.state = "3";
        if (nodeInfoes[_item.id]) {
          _item.history = nodeInfoes[_item.id];
          _item.state = "1";
        }
        if (strcurrentIds.indexOf(_item.id) > -1) {
          _item.state = "0";
        }
        if (_item.isAllAuditor == "2") {
          _item.name += "<br/>【多人审核:";

          if (_item.auditorType == "1") {
            _item.name += "并行】";
          } else {
            _item.name += "串行】";
          }
        }
        nodeMap[_item.id] = _item;
      });
      console.log(scheme);
      $("#wf_shcemeinfo").lrworkflowSet("set", { data: scheme });
    },
    initWorkflowDesigner() {
      $("#wf_shcemeinfo").lrworkflow({
        isPreview: true,
        openNode: function (node) {
          top.wflookNode = node;
          if (node.history) {
            
          }
        },
      });
    },
    removeItem(e) {
      let target = e.target.dataset.target;
      let index = e.target.dataset.index;
      let temp = this.formModel[target];
      temp = temp.filter((value, i) => {
        return i != index;
      });
      this.formModel[target] = temp;
    },
    cancle() {
      this.close();
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        console.log(valid);
        // if (!valid) {
        //     message.error('表单验证失败，请检查后重新提交！')
        //     return false;
        // }

        let postData = {
          ...this.formModel,
        };

        postData.scheme = JSON.stringify(
          $("#workflow-design-area").lrworkflowGet()
        );
        workflowSchemeApi
          .save(postData)
          .then((res) => {
            this.$notification["success"]({
              message: "保存成功",
            });
            this.close();
          })
          .catch((error) => {
            console.log("-----oh 我的玉帝 报错了---------");
            console.log(error);
          });
      });
    },
    updateSpinning(newV) {
      if (newV === "0") {
        this.spinning = false;
      } else {
        this.spinning = true;
      }
    },
  },
};
</script>

<style scoped lang="less">
.modal-header {
  margin-bottom: 0;

  .anticon {
    margin-right: 10px;
  }
}

.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>

<style src="@/styles/workflow/zr-workflow-ui.css"></style>


<style>
.step-pane-base {
  padding: 10px;
  padding-top: 69px;
}

.lr-alert {
  position: absolute;
  top: 10px;
  left: 0;
  height: 59px;
  width: 100%;
  padding: 0 10px;
}

.lr-alert .alert {
  margin-bottom: 0;
}

.lr-alert .alert > i {
  position: relative;
  top: 1px;
  font-size: 15px;
  padding-right: 5px;
}

.lr-panel {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  padding-top: 38px;
}

.lr-panel .panel-heading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 38px;
}

.lr-panel .panel-body {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 383px 0 15px 0;
}

.lr-form-item {
  position: absolute;
  top: 0;
  left: 0;
  height: 38px;
  width: 100%;
  padding-right: 20px;
}

.wizard {
  z-index: 3;
}

.btn-tool-bar {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: right;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.step-gird-pane {
  padding-top: 41px;
}

.btn-tool-bar-left {
  position: absolute;
  left: 5px;
  top: 6px;
  width: 300px;
  height: 28px;
  z-index: 4;
  text-align: left;
}
</style>
